import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';

import { Field, ActionSheet,DatetimePicker,Popup,Toast,Button } from 'vant';

createApp(App)
  .use(store)
  .use(router)
  .use(Field)
  .use(ActionSheet)
  .use(DatetimePicker)
  .use(Popup)
  .use(Toast)
  .use(Button)
  .mount('#app')

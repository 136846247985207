import { Toast } from 'vant';
import request from '@/utils/request';
import { reactive } from 'vue';

const useSmsCode = () => {
  const state = reactive({
    disabled: false,
    time: 60,
  });

  const send = async (mobile, tag = undefined) => {
    state.disabled = true;
    const interval = window.setInterval(() => {
      if (state.time-- <= 0) {
        state.time = 60;
        state.disabled = false;
        window.clearInterval(interval);
      }
    }, 1000);

    return new Promise((resolve, reject) => {
      request
        .post('/sms/SendCodeByAliyun', { mobile, tag })
        .then(() => {
          Toast.success('验证码发送成功,请注意查收')
          resolve();
        })
        .catch(err => {
          clearInterval(interval);
          state.time = 60;
          state.disabled = false;
          reject(err);
        });
    });
  };

  return {
    state,
    send,
  };
};

export default useSmsCode;

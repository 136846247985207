/**
 * 这里的配置仅在开发环境生效
 * 同名配置会覆盖 common.js 中的配置项
 */
export default {
  app_name: 'vip-manager-register-page-dev',
  env: 'development',
  env_dev: true,
  env_pro: false,
  debug: false,
  // api请求地址
  api_host: 'http://api.vip.php.qwangluo.net:90',
  // api_host: 'https://api.zc.qwangluo.net',
  // 文件存储的位置   server 服务器    oss 阿里云对象存储
  remote_file_source: 'oss',
  // 当 remote_file_source 为 oss 时候生效
  ali_oss: {
    bucket_host: '',
    bucket_name: '',
  },
};

/**
 * 通用配置,在开发环境和线上环境都生效
 * development.js 和 production.js 中的同名配置会覆盖这里的配置
 */
export default {
  app_name: 'vip-manager-register-page',
  env: 'production',
  env_dev: false,
  env_pro: true,
  debug: false,
  // api请求地址
  api_host: '',
  // 是否启用socket(预留,未实现)
  socket_disabled: true,
  // socket地址(预留,未实现)
  socket_host: '',
  // 文件存储的位置   server 服务器    oss 阿里云对象存储
  remote_file_source: 'server',
  // 当 remote_file_source 为 oss 时候生效
  ali_oss: {
    bucket_host: '',
    bucket_name: '',
  },
};

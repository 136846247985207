<template>
  <div class="home-view-container">
    <div class="con2">
      <div class="con3">
        <div class="t1">齐门鲁味餐饮悦积分会员管理</div>
        <div class="t2">{{ state.shopName }}</div>
      </div>
      <img src="../assets/img1.png" alt="" class="img1" />
    </div>
    <div class="con4">
      <div class="con5">填写您的资料</div>
      <div class="con6">
        <div class="con7">
          <div class="t3">姓名</div>
        </div>
        <input type="text" class="input1" v-model="formModel.name">
      </div>
      <div class="con6">
        <div class="con7">
          <div class="t3">手机号</div>
        </div>
        <input type="number" class="input1" v-model="formModel.mobile">
      </div>
      <div class="con6">
        <div class="con7">
          <div class="t3">验证码</div>
        </div>
        <div class="con9">
          <input type="text" class="input1" v-model="formModel.code">
        </div>
        <div class="con81" v-if="smsState.disabled">{{ smsState.time }}S</div>
        <div class="con8" @click="sendCode" v-else>获取验证码</div>
      </div>
      <div class="con6" @click="state.genderShow=true">
        <div class="con7">
          <div class="t3">性别</div>
        </div>
        <div class="con9">{{ state.genderName }}</div>
        <img src="../assets/arrow.png" alt="" class="img2" />
      </div>
      <div class="con6" @click="state.birthdayShow=true">
        <div class="con7">
          <div class="t3">生日</div>
        </div>
        <div class="con9">{{ formModel.birthday }}</div>
        <img src="../assets/arrow.png" alt="" class="img2" />
      </div>

      <div class="con11">
        <van-button type="primary" class="btn1" :disabled="state.loading" @click="submitHandle">提交</van-button>
      </div>

    </div>

    <van-action-sheet
        v-model:show="state.genderShow"
        :actions="genderOptions"
        cancel-text="取消"
        close-on-click-action
        @select="onSelectGender"
    />

    <van-popup
        v-model:show="state.birthdayShow"
        position="bottom"
    >
      <van-datetime-picker
          v-model="state.currentDate"
          type="date"
          @cancel="state.birthdayShow=false"
          @confirm="onSelectBirthday"
          :min-date="state.minDate"
          :max-date="state.maxDate"
      />
    </van-popup>

  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import moment from 'moment';
import { Toast, Dialog } from 'vant';
import useSmsCode from '@/utils/useSmsCode';
import request, { getQueryVariable } from '@/utils/request';

export default defineComponent({
  name: "HomeView",
  setup(props, { emit }) {
    const state = reactive({
      genderShow: false,
      genderName: undefined,
      birthdayShow: false,
      loading: false,
      shopName: '',
      shopId: undefined,
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(2022, 11, 31),
      currentDate: new Date(1990, 0, 1),
    });
    const { state: smsState, send } = useSmsCode();

    const genderOptions = [
      { name: '男', value: 1 },
      { name: '女', value: 2 },
    ];
    const formModel = reactive({
      name: undefined,
      gender: undefined,
      birthday: undefined,
      mobile: undefined,
      code: undefined,
    })

    const onSelectGender = (item) => {
      formModel.gender = item.value;
      state.genderName = item.name;
    }
    const onSelectBirthday = (item) => {
      formModel.birthday = moment(item).format('YYYY-MM-DD');
      state.birthdayShow = false;
    }

    const sendCode = () => {
      let mobile = formModel.mobile;
      if (!mobile) {
        Toast('请输入手机号');
        return false;
      }
      mobile = mobile + '';
      if (!mobile.match(/1\d{10}/)) {
        Toast('请输入正确的手机号');
        return false;
      }
      send(formModel.mobile);
    }


    const getShop = () => {
      const id = getQueryVariable('shop_id');
      if (!id) {
        alert('请扫描商铺二维码进入');
        return false;
      }
      request.post('/index/getShopInfo', { id }).then(res => {
        if (!res.id) {
          alert('无效的商铺二维码');
          return false;
        }
        state.shopId = res.id;
        state.shopName = res.nick_name;
      })
    }
    getShop();


    const submitHandle = () => {
      if (state.loading) return false;
      const { name, mobile, code, gender, birthday } = formModel;
      if (!name || !mobile || !code || !gender || !birthday) {
        Toast('请完整填写表单');
        return false;
      }
      state.loading = true;
      request.post('/index/register', {
        shop_id: state.shopId,
        mobile: mobile + '',
        name, code, gender, birthday
      }).then(res => {
        state.loading = false;
        Dialog.alert({
          title: '成功',
          message: '资料提交完成',
        }).then(() => {
          location.reload();
        });
      }).finally(() => {
        state.loading = false;
      })

    }

    return {
      state,
      formModel,
      genderOptions,
      onSelectGender,
      onSelectBirthday,
      smsState,
      sendCode,
      submitHandle,
    };
  },
})
</script>

<style scoped lang="less">
.home-view-container {
  width: 100%;
  height: 100%;
  background-color: #4A8EF5;
  overflow-y: scroll;

  .con2 {
    display: flex;
    flex-direction: row;
    margin: 15px 15px 0 15px;

    .con3 {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 8px;

      .t1 {
        font-size: 24px;
        font-weight: bold;
        color: white;
        line-height: 33px;
      }

      .t2 {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 20px;
        margin-top: 8px;
      }
    }

    .img1 {
      width: 156px;
      height: 101px;
    }
  }

  .con4 {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    background: #FFFFFF;
    box-shadow: 0 3px 10px 1px rgba(74, 142, 245, 0.1);
    border-radius: 6px;
    margin-bottom: 64px;

    .con5 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 29px;
      padding-bottom: 25px;
      font-size: 15px;
      color: #333333;
      font-weight: bold;
      text-align: center;
    }

    .con6 {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 60px;
      background-color: #f9f9f9;
      border-radius: 6px;
      margin: 0 15px 12px 15px;

      .con7 {
        display: flex;
        width: 100px;
        min-width: 100px;

        .t3 {
          margin-left: 20px;
          font-size: 15px;
        }
      }

      .input1 {
        width: 100%;
        border: 0;
        font-size: 15px;
        height: 44px;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
      }

      .con9 {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
      }

      .con8 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100px;
        height: 100%;
        text-align: right;
        padding-right: 15px;
        font-size: 15px;
        color: #4A8EF5;
      }

      .con81 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100px;
        height: 100%;
        text-align: right;
        padding-right: 15px;
        font-size: 15px;
        color: #ccc;
      }

      .img2 {
        width: 13px;
        height: 7px;
        margin-right: 15px;
      }
    }

    .con11 {
      display: flex;
      flex-direction: column;
      margin: 42px 44px;

      .btn1 {
        border-radius: 22px;
      }
    }
  }
}
</style>

/**
 * 这里的配置仅在线上环境生效
 * 同名配置会覆盖 common.js 中的配置项
 */
export default {
  app_name: 'vip-manager-register-page-prod',
  env: 'production',
  env_dev: false,
  env_pro: true,
  debug: false,
  // api请求地址
  api_host: 'https://api.qm.sdrongruan.com',
  // 文件存储的位置   server 服务器    oss 阿里云对象存储
  remote_file_source: 'server',
  // 当 remote_file_source 为 oss 时候生效
  ali_oss: {
    bucket_host: '',
    bucket_name: '',
  },
};

import axios, { AxiosResponse } from 'axios';
import { Toast } from 'vant';
import config from '@/config';

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头

export const REQUEST_TOKEN_KEY = 'Authorization';

// 创建 axios 实例
const request = axios.create({
  baseURL: config.api_host,
  // API 请求的默认前缀
  timeout: 6000, // 请求超时时间
}); // 异常拦截处理器

const errorHandler = error => {
  if (error.response) {
    const { data = {}, status } = error.response;
    if (status === 400 && data.error) {
      Toast(data.error);
    } else if (status === 404) {
      Toast('请求路径不存在');
    } else {
      Toast('服务器返回错误');
    }
  } else {
    Toast('请求失败');
  }

  return Promise.reject(error);
}; // 请求拦截器

const requestHandler = config => {
  // 多语言
  config.headers['Accept-Language'] = 'zh_cn'

  return config;
}; // Add a request interceptor

request.interceptors.request.use(requestHandler, errorHandler); // 响应拦截器

const responseHandler = response => {
  return response.data.data;
}; // Add a response interceptor

request.interceptors.response.use(responseHandler, errorHandler);
export { AxiosResponse };
export default request;

/**
 * 从请求url中获取参数
 * @param variable
 * @param default_value
 * @returns {string}
 */
export const getQueryVariable = (variable, default_value = '') => {
  let query = window.location.search.substring(1);
  if (!query) {
    const hash = window.location.hash.split('?');
    if (hash.length >= 2) {
      query = hash[1];
    }
  }
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return default_value;
};
